import TitleCard from "./Subcomponents/TitleCard";
import QuestionCard from "./Subcomponents/QuestionCard";
import "./GamePannel.css"
import AnswerPannel from "./Subcomponents/AnswerPannel";
import GameState from "../Model Defs/GameState"
import Card from "../Model Defs/Card";
import axios from 'axios';
// try to keep all the imports here

// Game pannel is the source of truth as there can only possibly be 1 of it rendered at a time
function GamePannel() {
    var currentState = new GameState(0,0,0,0)
    var deck = [100,false.Card]
    var displayedCard = new Card(1,true,false,0,"Do you like chappel roan?",2,"Yes",2)
    var nextCard = new Card(1,true,false,0,"Do you like chappel roan?",2,"Yes",2)
    function reloadPannel(arg1) {
        alert(arg1)
    }
    function setData(deckslot, data) {
        deck[deckslot-1] = data
    }
    function readCard() {

    }
    function request(arg1) {
        const url = "http://54.79.8.218:8000/api"
        axios({
            method: 'get',
            url: url,
            responseType: "application/x-www-form-urlencoded; charset=UTF-8",
            headers: {"card-type":arg1}
            
        })
        .then(response => {
          console.log(response.data)
          var obj = JSON.parse(response.data)
          console.log(obj.carddesc)
          
         
        })
        .catch(error => {
          console.log(error);
        });
    }
    return (
        <div className="GamePannel">
            <TitleCard />
            <QuestionCard display={displayedCard.question} />
            <AnswerPannel answer={displayedCard.question} clickHandler={request}/>
        </div>
    );
}

export default GamePannel
