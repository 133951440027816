import './App.css';
import GamePannel from './Components/GamePannel';

function App() {
  
  return (
    <div className="App">
      <header className="App-header">
      <GamePannel />
      </header>
    </div>
  );
}

export default App;
