import AnswerButton from "./AnswerButton";
import "./AnswerPannel.css"

function AnswerPannel({answer, clickHandler}) {
    return (
        <div className="AnswerPannel">
            <AnswerButton className="Abutton" id={1} handleClick={clickHandler} answertext={answer}/>
            <AnswerButton className="Abutton" id={2} handleClick={clickHandler} answertext={answer}/>
            <AnswerButton className="Abutton" id={3} handleClick={clickHandler} answertext={answer}/>
            <AnswerButton className="Abutton" id={4} handleClick={clickHandler} answertext={answer}/>
        </div>
    );
}
// lot of code repition, use a for loop? i wont ever need more then 4 buttons so seems kinda pointless....
export default AnswerPannel