import "./AnswerButton.css"
function AnswerButton({id, answertext, handleClick}) {
    return (
        <div className="AnswerButton">
            <button className="buttonStyle" role="button" onClick={() => handleClick(id)}> {answertext} </button>
        </div>
    );
}

export default AnswerButton

// should probably make a more generic button component and then subclass it? 