import "./QuestionCard.css"
function QuestionCard(props) {
    var questionText = props.display
    return (
        <div className="QuestionCard">
            <p className="rawtext"> {questionText} </p>          
        </div>
    );
}

export default QuestionCard